/* eslint-disable max-len */
import { NextSeoProps } from "next-seo";
import React, { useEffect, useState } from "react";
import dynamic from "next/dynamic";
import { isMobile } from "react-device-detect";
import classNames from "classnames";
import BlogKienThucWrap from "@Modules/homev2/components/blog-kien-thuc/BlogKienThucWrap";
import DuAnHoanThienWrap from "@Modules/homev2/components/du-an-hoan-thien/DuAnHoanThienWrap";
import HinhAnhNoiThatWrap from "@Modules/homev2/components/hinh-anh-noi-that/HinhAnhNoiThatWrap";
import ContactUs from "@Components/contactUs";
import HomePage from "@Models/homepage.model";
import EndOfPage from "@Pages/quy-trinh-ket-noi/components/end-of-page";
import Keyword, { EType } from "@Models/keyword.model";
import redirectToApp from "@Utils/redirectToApp";
import MainCategory from "@Modules/homev2/components/MainCategory";
import HornorBrand from "@Modules/homev2/components/HornorBrand";
import NewsBrand2 from "@Modules/homev2/components/NewsBrand2";
import BenefitWithSpaceT from "@Modules/homev2/components/BenefitWithSpaceT";
import Banner, { EBannerDevice, EBannerSection, IBanner } from "@Models/banner.model";
import { getDevices } from "utils/SSR/index.handler";
import CalculateCosts from "@Modules/homev2/components/CalculateCosts";
import Review from "@Modules/homev2/components/KhachHangReview";
import SpacetParner from "@Modules/homev2/components/SpacetParner";
import ArticleCategory from "@Models/articleCategory.model";
import JustThreeStep from "@Modules/homev2/components/JustThreeStep";
import FAQ from "@Pages/quy-trinh-ket-noi/components/faq/FAQ";
import ModalMain from "@Components/modal/ModalMain";
import NhanThuongFriend from "@Modules/homev2/components/NhanThuongFriend";
import { ModelError } from "@Models/model";
import GeneralMediasBySpace, { EGeneralMediasBySpaceSortBy } from "@Models/generalMediasBySpace.model";
import ActualWork, { EActualWorkTypes } from "@Models/actualWork.model";
import { ISSRData as ISSRDataDuAnHoanThien } from "@Modules/homev2/components/du-an-hoan-thien/DuAnHoanThienContent";
import Article from "@Models/article.model";
import { IData as IDataHinhAnhNoiThat } from "@Modules/homev2/components/hinh-anh-noi-that/HinhAnhNoiThatContent";
import { TListRoom } from "@Models/keyword.model";
import Community from "@Models/community.model";
import Form from "./components/form/Form";

interface IMenu {
   title: string;
   icon: string;
   iconLg: string;
   path: string;
}

const Banners = dynamic(() => import("@Modules/homev2/components/Banners"), {
   loading: () => <></>,
});

const Procedure = dynamic(() => import("./components/procedure/Procedure"), {
   loading: () => <></>,
});

const OurDrawing = dynamic(() => import("./components/ourDrawing/OurDrawing"), {
   loading: () => <></>,
});
const Calculator = dynamic(() => import("./components/calculator/Calculator"), {
   loading: () => <></>,
});

const Partners = dynamic(() => import("./components/partner/Partner"), {
   loading: () => <></>,
});

const MENUS: IMenu[] = [
   {
      title: "Hình ảnh nội thất",
      icon: "/assets/img/menu/library.png",
      iconLg: "/assets/img/menu/library.png",
      path: "/thu-vien-anh",
   },
   {
      title: "Dự án hoàn thiện",
      icon: "/assets/img/menu/project-finish.png",
      iconLg: "/assets/img/menu/project-finish.png",
      path: "/thu-vien-thiet-ke",
   },
   {
      title: "Dự toán miễn phí",
      icon: "/assets/img/menu/calculate.png",
      iconLg: "/assets/img/menu/calculate.png",
      path: "/bao-gia",
   },
   {
      title: "Thiết kế 2D/3D miễn phí",
      icon: "/assets/img/menu/2d-3d.png",
      iconLg: "/assets/img/menu/2d-3d.png",
      path: "/thiet-ke-2d-3d",
   },
   {
      title: "Kiến thức",
      icon: "/assets/img/menu/kien_thuc.png",
      iconLg: "/assets/img/menu/kien_thuc.png",
      path: "/blog",
   },
];

type IProps = {
   categoriesHinhAnhNoiThat: TListRoom;
   dataOfSSRHinhAnhNoiThatBySlug: {
      docs?: IDataHinhAnhNoiThat[];
   };
   dataOfSSRHinhAnhNoiThat: {
      docs?: IDataHinhAnhNoiThat[];
   };
   blogCategories: Keyword[];
   banners: Banner[];
   dataHomePage: any;
   dataOfSSRCanHoChungCuBySlug: ISSRDataDuAnHoanThien;
   dataOfSSRThuVienThietKe: ISSRDataDuAnHoanThien;
   dataOfSSRCongTrinhThucTe: ISSRDataDuAnHoanThien;
   dataOfSSRToAmCuaToi: Community;
   dataOfSSRKienThuc: Article[];
   totalDuAnThucTe: number;
};

const ProcedureConnection: React.FC<IProps> = ({
   categoriesHinhAnhNoiThat,
   dataOfSSRHinhAnhNoiThatBySlug,
   dataOfSSRHinhAnhNoiThat,
   blogCategories,
   banners,
   dataHomePage,
   dataOfSSRCanHoChungCuBySlug,
   dataOfSSRThuVienThietKe,
   dataOfSSRCongTrinhThucTe,
   dataOfSSRToAmCuaToi,
   dataOfSSRKienThuc,
   totalDuAnThucTe,
}) => {
   const [isPopupDangkyTuVan, setIsPopupDangkyTuVan] = useState(false);

   useEffect(() => {
      redirectToApp(`spacet://construction/quy-trinh-ket-noi`);
      if (typeof window !== "undefined") {
         window.scrollTo(0, 0);
      }
   }, []);

   const handleOpenPopupDangkyTuVan = () => {
      setIsPopupDangkyTuVan(true);
   };

   const handleClosePopupDangkyTuVan = () => {
      setIsPopupDangkyTuVan(false);
   };

   return (
      <>
         <div className="page-content" id="pageContent">
            <main className="home-pagev2">
               <h1 className="d-none">Dịch vụ thiết kế và thi công nội thất toàn diện</h1>
               <div className="block_banner">
                  <div
                     className={classNames("st-container", {
                        "pb-0 px-3": !isMobile,
                        "px-0 py-0": isMobile,
                     })}
                  >
                     {/*** banner */}
                     <Banners
                        data={banners.filter((banner) => banner.section === EBannerSection.interiorHome)}
                        bannerRight={banners.filter((banner) => banner.section === EBannerSection.interiorhomeright)}
                     />
                     {/* finish banner */}

                     {/*** form Thiết kế nội thất, để Space T giúp bạn */}
                     <div className="st-form">
                        <Form />
                     </div>
                     {/* finish form Thiết kế nội thất, để Space T giúp bạn */}
                  </div>
                  <div className={`st-container ${!isMobile ? "px-3 pb-4" : "px-0"}`}>
                     <MainCategory menus={MENUS} />
                     <HornorBrand />
                     <NewsBrand2 />
                  </div>
               </div>

               {/* section of main content */}
               <div className="main-content">
                  <div className="st-container">
                     <div className="row g-2 g-md-3">
                        {/*** Hình ảnh nội thất */}
                        <HinhAnhNoiThatWrap
                           categories={categoriesHinhAnhNoiThat}
                           dataBySlug={dataOfSSRHinhAnhNoiThatBySlug}
                           datas={dataOfSSRHinhAnhNoiThat}
                        />

                        {/*** launch for Dự án hoàn thiện */}
                        <DuAnHoanThienWrap dataBySlug={dataOfSSRCanHoChungCuBySlug} total={totalDuAnThucTe} />

                        {/*** launch for Lợi ích khi kết nối với Space T  */}
                        <BenefitWithSpaceT />

                        {/*** launch for Làm nội thất dễ dàng chỉ với 3 bước  */}
                        <JustThreeStep handleOpenPopupDangkyTuVan={handleOpenPopupDangkyTuVan} />

                        {/* --- launch for Tính chi phí  */}
                        <CalculateCosts />

                        {/* --- launch for Khách hàng nói gì về Space T */}
                        <Review />

                        {/* --- launch for Đối tác của Space T */}
                        <SpacetParner />

                        {/* --- launch for Giới thiệu bạn bè, nhận ngay tiền thưởng */}
                        <NhanThuongFriend />

                        {/* --- launch for Kiến thức  */}
                        <BlogKienThucWrap categories={blogCategories} datasSSR={dataOfSSRKienThuc as any} />

                        {/* --- launch for Cau hoi thuong gap */}
                        <div className="block">
                           <FAQ />
                        </div>

                        {/* --- launch for Space T giúp nội thất của bạn trở nên hoàn hảo hơn */}
                        <ContactUs handleOpenPopupDangkyTuVan={handleOpenPopupDangkyTuVan} />

                        {false && (
                           <div className="block">
                              <Procedure />
                           </div>
                        )}
                        {false && (
                           <div className="block connect-service-page">
                              {/* <ImageBySpaces
                                 {...props}
                                 keywordGroupsByType={keywordGroupsByType}
                              /> */}
                           </div>
                        )}
                        {false && (
                           <div className="block connect-service-page">
                              <OurDrawing />
                           </div>
                        )}
                        {false && (
                           <div className="block">
                              <Calculator />
                           </div>
                        )}
                        {false && (
                           <div>
                              <Review />
                           </div>
                        )}
                        {false && (
                           <div className="block">
                              <Partners partners={dataHomePage?.partner} />
                           </div>
                        )}
                     </div>
                  </div>
               </div>
               {/* --- launch for end section of main content */}
               <EndOfPage
                  dataOfSSRHinhAnhNoiThat={dataOfSSRHinhAnhNoiThat}
                  dataOfSSRThuVienThietKe={dataOfSSRThuVienThietKe}
                  dataOfSSRCongTrinhThucTe={dataOfSSRCongTrinhThucTe}
                  dataOfSSRToAmCuaToi={dataOfSSRToAmCuaToi}
               />
               {/* end section of main content */}
            </main>
         </div>
         <ModalMain
            open={isPopupDangkyTuVan}
            title="Tư vấn thiết kế nội thất"
            maxWidth="630px"
            onClose={handleClosePopupDangkyTuVan}
         >
            <Form vertical={true} />
         </ModalMain>
      </>
   );
};

export default ProcedureConnection;

export const getServerSideProps = async (ctx) => {
   const { isMobile } = getDevices(ctx.req);
   const urlParams = new URLSearchParams((ctx as any).query);

   // *** seo
   const title = "Dịch vụ thiết kế và thi công nội thất toàn diện - Space T";
   const description =
      "Space T mang đến dịch vụ nội thất toàn diện từ thiết kế nội thất, thi công nội thất đến đồ nội thất cố định và nội thất rời, với 1000+ công trình đã hoàn thiện. Khám phá ý tưởng nội thất đẹp, nhận tư vấn từ chuyên gia, báo giá rõ ràng không chi phí ẩn, bảo hành 1+ năm. Liên hệ nhận tư vấn miễn phí.";
   const url = "https://spacet-release.s3.ap-southeast-1.amazonaws.com/img/seo/procedure.jpg";
   const seo: NextSeoProps = {
      title,
      description,
      openGraph: {
         title,
         type: "website",
         description,
         images: [
            {
               url,
            },
         ],
      },
   };
   // * finish seo

   // *** fetch data hinh anh noi that
   const categoriesHinhAnhNoiThatResponse = await Keyword.getKeywordsByType([EType.space]);
   if (categoriesHinhAnhNoiThatResponse instanceof ModelError) {
      throw new Error("Failed to fetch categories HinhAnhNoiThat");
   }
   const categoriesHinhAnhNoiThatSSR = JSON.parse(JSON.stringify(categoriesHinhAnhNoiThatResponse));
   // * finish fetch data hinh anh noi that

   // *** hinh anh noi that
   const firstSlugHinhAnhNoiThat = categoriesHinhAnhNoiThatSSR?.space?.[0]?.info?.slug ?? "";
   let dataOfSSRHinhAnhNoiThatBySlug = [];
   if (firstSlugHinhAnhNoiThat) {
      const responseHinhAnhNoiThatBySlug = await GeneralMediasBySpace.paginatev2({
         page: 1,
         limit: 4,
         url: firstSlugHinhAnhNoiThat,
         sort: "desc",
         sortBy: EGeneralMediasBySpaceSortBy.viewCount,
      });
      if (responseHinhAnhNoiThatBySlug instanceof ModelError) {
         throw new Error("Failed to fetch galleries");
      }
      dataOfSSRHinhAnhNoiThatBySlug = JSON.parse(JSON.stringify(responseHinhAnhNoiThatBySlug));
   }

   const responseHinhAnhNoiThat = await GeneralMediasBySpace.paginatev2({
      page: 1,
      limit: 4,
      sort: "desc",
      sortBy: EGeneralMediasBySpaceSortBy.viewCount,
   });
   if (responseHinhAnhNoiThat instanceof ModelError) {
      throw new Error("Failed to fetch galleries");
   }
   const dataOfSSRHinhAnhNoiThat = JSON.parse(JSON.stringify(responseHinhAnhNoiThat));

   // * finish hinh anh noi that

   // *** du an hoan thien
   const canHoChungCuBySlugResponse = await ActualWork.getPaginate({
      limit: 4,
      articleType: EActualWorkTypes.designLibrary,
      url: "can-ho-chung-cu",
   });
   if (canHoChungCuBySlugResponse instanceof ModelError) {
      throw new Error("Failed to fetch galleries");
   }
   const dataOfSSRCanHoChungCuBySlug = JSON.parse(JSON.stringify(canHoChungCuBySlugResponse));

   const dataOfSSRThuVienThietKeResponse = await ActualWork.getPaginate({
      limit: 4,
      articleType: EActualWorkTypes.designLibrary,
   });
   if (dataOfSSRThuVienThietKeResponse instanceof ModelError) {
      throw new Error("Failed to fetch galleries");
   }
   const dataOfSSRThuVienThietKe = JSON.parse(JSON.stringify(dataOfSSRThuVienThietKeResponse));

   const congTrinhThucTeResponse = await ActualWork.getPaginate({
      limit: 4,
      articleType: EActualWorkTypes.actualWork,
   });
   if (congTrinhThucTeResponse instanceof ModelError) {
      throw new Error("Failed to fetch galleries");
   }
   const dataOfSSRCongTrinhThucTe = JSON.parse(JSON.stringify(congTrinhThucTeResponse));

   const toAmCuaToiResponse = await Community.paginate({
      page: Number(urlParams.get("page")) || 1,
      limit: 4,
      sort: urlParams.get("sort") === "asc" ? "asc" : "desc",
   });
   const dataOfSSRToAmCuaToi = JSON.parse(JSON.stringify(toAmCuaToiResponse));

   const totalDuAnThucTe = [dataOfSSRThuVienThietKe, dataOfSSRCongTrinhThucTe, dataOfSSRToAmCuaToi].reduce(
      (total, data) => total + (data?.paginate?.totalDocs || 0),
      0
   );

   // * finish du an hoan thien

   // --- for Variable
   let dataHomePage: any = {};
   let banners: IBanner[] = [];
   let blogCategories: any = [];

   // --- for Promise
   const dataHomePagePromise = HomePage.listHomePage();
   const bannerPromise = Banner.getBanners({
      sections: [EBannerSection.interiorHome, EBannerSection.interiorhomeright],
      devicetype: isMobile ? EBannerDevice.mobileweb : EBannerDevice.web,
   });
   const blogCategoriesPromise = ArticleCategory.paginateArticleCategory({
      limit: 20,
      sort: "asc",
      sortBy: "info.position",
   });

   // for Promise all
   await Promise.all([dataHomePagePromise, bannerPromise, blogCategoriesPromise]).then((data) => {
      dataHomePage = JSON.parse(JSON.stringify(data[0]));
      banners = JSON.parse(JSON.stringify(data[1]));
      blogCategories = JSON.parse(JSON.stringify(data[2]));
   });

   // --- get ssr for Blog
   let dataOfSSRKienThuc = [];
   const slugOfKienThuc = (blogCategories as any)?.docs?.[0]?.info?.slug ?? "";

   if (slugOfKienThuc) {
      const response = await Article.paginate({
         limit: 4,
         categorySlugs: slugOfKienThuc,
         cookiesToken: "",
      });

      dataOfSSRKienThuc = JSON.parse(JSON.stringify(response));
   }

   return {
      props: {
         categoriesHinhAnhNoiThat: categoriesHinhAnhNoiThatSSR || [],
         dataOfSSRHinhAnhNoiThatBySlug: dataOfSSRHinhAnhNoiThatBySlug || [],
         dataOfSSRHinhAnhNoiThat: dataOfSSRHinhAnhNoiThat || [],
         dataOfSSRCanHoChungCuBySlug: dataOfSSRCanHoChungCuBySlug || [],
         dataOfSSRThuVienThietKe: dataOfSSRThuVienThietKe || [],
         dataOfSSRCongTrinhThucTe: dataOfSSRCongTrinhThucTe || [],
         dataOfSSRToAmCuaToi: dataOfSSRToAmCuaToi || [],
         dataHomePage,
         banners,
         blogCategories,
         dataOfSSRKienThuc,
         seo,
         totalDuAnThucTe,
      },
   };
};

import { useEffect, useState } from "react";
import classNames from "classnames";
import LayoutContainer from "@Components/Container";
import styles from "./index.module.scss";

const EndOfPage = ({
   dataOfSSRHinhAnhNoiThat,
   dataOfSSRThuVienThietKe,
   dataOfSSRCongTrinhThucTe,
   dataOfSSRToAmCuaToi,
}) => {
   const [loadMore, setLoadMore] = useState<boolean>(false);

   const loadMoreContent = () => setLoadMore((prev) => !prev);

   useEffect(() => {
      if (typeof window !== "undefined") {
         setLoadMore(true);
      }
   }, []);

   return (
      <div className="content_end_page" style={{ backgroundColor: "#EFF2F5", paddingBottom: "20px" }}>
         <LayoutContainer>
            <div className="">
               <div className="content_item">
                  <h2 className={styles["content_title"]}>THIẾT KẾ THI CÔNG NỘI THẤT CÙNG SPACE T</h2>
                  <div className="content_description">
                     Space T cung cấp toàn diện dịch vụ thiết kế, thi công và nội thất, đáp ứng nhu cầu của bạn với chất
                     lượng cao nhất. Bạn có thể tìm thấy cảm hứng hoàn thiện nội thất, tham khảo công trình nội thất,
                     tìm hiểu kiến thức về nhà cửa đời sống, tự tay thiết kế không gian với công cụ thiết kế 2D/3D
                     online hiện đại cũng như dự tính ngân sách. Liên hệ để nhận tư vấn phù hợp cho mọi nhu cầu hoàn
                     thiện nội thất.
                     {loadMore && (
                        <span className="btn_see_more see_more" onClick={loadMoreContent}>
                           Xem thêm
                        </span>
                     )}
                  </div>
               </div>
               <div className={classNames(["more_content"], [loadMore ? ["see_more"] : "see_less"])}>
                  <div className="content_item">
                     <h2 className={styles["content_title"]}>Thư viện hình ảnh nội thất - ngoại thất</h2>
                     <div className="content_description">
                        Tổng hợp {dataOfSSRHinhAnhNoiThat?.totalDocs} hình ảnh thiết kế, hoàn thiện đẹp cho các không
                        gian nội thất như{" "}
                        <a title="phòng ăn" href="/thu-vien-anh/mau-phong-an">
                           phòng ăn
                        </a>
                        ,{" "}
                        <a title="phòng khách" href="/thu-vien-anh/mau-phong-khach">
                           phòng khách
                        </a>
                        ,{" "}
                        <a title="phòng bếp" href="/thu-vien-anh/mau-phong-bep">
                           phòng bếp
                        </a>
                        ,{" "}
                        <a title="phòng ngủ" href="/thu-vien-anh/mau-phong-ngu">
                           phòng ngủ
                        </a>
                        ,{" "}
                        <a title="phòng tắm" href="/thu-vien-anh/mau-phong-tam">
                           phòng tắm
                        </a>
                        ,{" "}
                        <a title="phòng làm việc" href="/thu-vien-anh/mau-phong-lam-viec">
                           phòng làm việc
                        </a>
                        ,{" "}
                        <a title="phòng cho bé" href="/thu-vien-anh/mau-phong-cho-be">
                           phòng cho bé
                        </a>
                        ,{" "}
                        <a title="lối vào" href="/thu-vien-anh/mau-loi-vao">
                           lối vào
                        </a>
                        ,{" "}
                        <a title="phòng thay đồ" href="/thu-vien-anh/mau-phong-thay-do">
                           phòng thay đồ
                        </a>
                        ,{" "}
                        <a title="phòng thờ" href="/thu-vien-anh/mau-phong-tho">
                           phòng thờ
                        </a>
                        ,{" "}
                        <a title="cầu thang" href="/thu-vien-anh/mau-cau-thang">
                           cầu thang
                        </a>
                        , và các khu vực ngoài trời như{" "}
                        <a title="sân vườn" href="/thu-vien-anh/mau-san-vuon">
                           sân vườn
                        </a>
                        , ban công,{" "}
                        <a title="lô gia" href="/thu-vien-anh/mau-lo-gia">
                           lô gia
                        </a>
                        ,{" "}
                        <a title="sân thượng" href="/thu-vien-anh/mau-san-thuong">
                           sân thượng
                        </a>
                        ,{" "}
                        <a title="mặt tiền" href="/thu-vien-anh/mau-mat-tien">
                           mặt tiền
                        </a>
                        .
                     </div>
                     <div className="content_description mt-2">
                        Thư viện ảnh bao gồm đa dạng hình ảnh ý tưởng theo các phong cách nội thất nổi bật như Modern
                        (Hiện đại), Scandinavian (Bắc u), Neo Classic (Tân cổ điển), Minimalist (Tối giản), Indochine
                        (Đông Dương) cùng các tông màu thông dụng nhất. Gia chủ cũng có thể xem chi tiết không gian và
                        các gợi ý tương tự để có thêm nhiều cảm hứng hoàn thiện tổ ấm của mình.
                     </div>
                  </div>
                  <div className="content_item">
                     <h2 className={styles["content_title"]}>Dự án hoàn thiện nội thất</h2>
                     <div className="content_description mt-2">
                        <a className="fw-bold" href="/thu-vien-thiet-ke" title="Thư viện thiết kế">
                           Thư viện thiết kế
                        </a>{" "}
                        - {dataOfSSRThuVienThietKe?.paginate?.totalDocs} mẫu thiết kế 2D, SketchUp, concept 3D nội thất
                        cho công trình nhà ở và công trình thương mại theo các phong cách thiết kế nổi bật hiện nay.
                     </div>
                     <div className="content_description mt-2">
                        <a className="fw-bold" href="/cong-trinh-thuc-te" title="Công trình thực tế">
                           Công trình thực tế
                        </a>{" "}
                        - {dataOfSSRCongTrinhThucTe?.paginate?.totalDocs} công trình thi công nội thất cho căn hộ chung
                        cư, nhà phố, biệt thự, văn phòng, quán cafe, nhà hàng,... đã hoàn thành bởi các nhà thầu thi
                        công nội thất uy tín.
                     </div>
                     <div className="content_description mt-2">
                        <a className="fw-bold" href="/to-am-cua-toi" title="Tổ ấm của tôi">
                           Tổ ấm của tôi
                        </a>{" "}
                        - Nơi để tất cả mọi người có thể khoe hình ảnh nhà cửa, không gian sống đẹp, chia sẻ quá trình,
                        kinh nghiệm tạo nên tổ ấm trong mơ.
                     </div>
                  </div>
                  <div className="content_item">
                     <h2 className={styles["content_title"]}>Công cụ tính chi phí thi công nội thất</h2>
                     <div className="content_description">
                        Ước tính chi phí thi công nội thất cho nhà ở với{" "}
                        <a href="/bao-gia" title="công cụ tính chi phí">
                           công cụ tính chi phí
                        </a>{" "}
                        thông minh, trực quan, hiển thị mức giá nhanh chóng với thao tác đơn giản. Chỉ cần lựa chọn và
                        điền những thông tin cần thiết, công cụ sẽ tự động đưa ra{" "}
                        <a
                           href="/bao-gia/nha-o"
                           title="báo giá thi công căn hộ, báo giá thi công nhà ở, báo giá thi công hệ tủ bếp, báo giá thi công tủ quần áo"
                        >
                           báo giá thi công căn hộ, báo giá thi công nhà ở, báo giá thi công hệ tủ bếp, báo giá thi công
                           tủ quần áo
                        </a>
                        . Bạn có thể dựa vào mức giá ước tính này để cân đối ngân sách cho nhu cầu hoàn thiện nội thất
                        của mình. Ngoài ra bạn cũng có thể gửi yêu cầu để nhận tư vấn, báo giá chi tiết một cách nhanh
                        chóng và chính xác nhất.
                     </div>
                  </div>
                  <div className="content_item">
                     <h2 className={styles["content_title"]}>Phần mềm thiết kế 2D/3D</h2>
                     <div className="content_description">
                        Sử dụng{" "}
                        <a href="/thiet-ke-2d-3d" title="phần mềm thiết kế nội thất 2D/3D online">
                           phần mềm thiết kế nội thất 2D/3D online
                        </a>{" "}
                        để lên ý tưởng cho không gian sống với các tính năng nổi bật:
                     </div>
                     <ul className="mt-2">
                        <li className="content_description mt-1">
                           Đầy đủ công cụ giúp bạn tạo bản vẽ mặt bằng layout 2D, bố trí nội thất cũng như tạo bản vẽ
                           phối cảnh 3D nhanh chóng, thuận tiện.
                        </li>
                        <li className="content_description mt-1">
                           Có sẵn hơn 1,000 layout mặt bằng các căn hộ trên khắp cả nước. Bạn có thể tìm và chọn căn hộ
                           của mình để bắt đầu thiết kế nội thất.
                        </li>
                        <li className="content_description mt-1">
                           Bộ tài liệu và các video hướng dẫn thiết kế đơn giản dễ hiểu, đầy đủ các bước giúp người
                           không chuyên có thể nắm bắt và sử dụng phần mềm một cách dễ dàng.
                        </li>
                        <li className="content_description mt-1">
                           Render nhanh chóng các thiết kế 3D với độ phân giải cao và lưu trữ online để sử dụng.
                        </li>
                        <li className="content_description mt-1">
                           Sử dụng phần mềm hoàn toàn miễn phí, giúp bạn tiết kiệm chi phí trong quá trình thiết kế nội
                           thất cho không gian sống.
                        </li>
                     </ul>
                  </div>
                  <div className="content_item">
                     <h2 className={styles["content_title"]}>Kiến thức nội thất</h2>
                     <div className="content_description">
                        Tổng hợp bài viết{" "}
                        <a href="/blog/kien-thuc-noi-that" title="kiến thức nội thất">
                           kiến thức nội thất
                        </a>
                        , gợi ý mẫu thiết kế và nhiều mẹo hay về xây dựng,{" "}
                        <a href="/blog/thiet-ke-noi-that" title="thiết kế nội thất">
                           thiết kế nội thất
                        </a>
                        ,{" "}
                        <a href="/blog/thi-cong-noi-that" title="thi công nội thất">
                           thi công nội thất
                        </a>
                        , trang trí và{" "}
                        <a href="/blog/cham-soc-nha" title="chăm sóc nhà cửa">
                           chăm sóc nhà cửa
                        </a>
                        . Thông tin được tổng hợp theo các chủ đề được nhiều người quan tâm khi làm nhà, làm nội thất
                        như{" "}
                        <a
                           href="/blog/phong-cach-noi-that"
                           title="các phong cách nội thất</a>, khái niệm các loại vật liệu thi công"
                        >
                           các phong cách nội thất
                        </a>
                        , khái niệm các loại vật liệu thi công,{" "}
                        <a href="/blog/nguon-cam-hung" title="ý tưởng thiết kế nội thất">
                           ý tưởng thiết kế nội thất
                        </a>{" "}
                        nổi bật, mẹo chăm sóc nhà, mẹo trang trí nhà, giúp bạn dễ dàng tìm hiểu, tham khảo để có thêm
                        nhiều kiến thức và cảm hứng tạo nên không gian sống đẹp và tiện nghi.
                     </div>
                  </div>
               </div>
            </div>
         </LayoutContainer>
      </div>
   );
};

export default EndOfPage;

import React, { useEffect, useRef, useState, useMemo } from "react";
import Link from "next/link";
import Slider, { Settings } from "react-slick";
import { useRouter } from "next/router";
import classnames from "classnames";
import { isMobile } from "react-device-detect";
import Article from "@Models/article.model";
import { truncateText } from "@Utils/index";
import SkeletonLoader from "@Components/skeleton-loader";
import Loadingv2 from "@Components/Loading/v2";
import { ImageView } from "@Components/image";
import styles from "./BlogKienThucContent.module.scss";

// --- for Category
// --- finish for Category

// --- for Props
interface IProps {
   categories: any;
   datasSSR?: any;
}

interface Doc {
   _id: string;
   info: {
      title: string;
      description: string;
      sapo: string;
      slug: string;
      publishedAt: string;
      viewCount: number;
   };
   __bookmarkCount: number;
   __media: {
      $url: string;
      $url3And4ColResizeMax: string;
   };
   __category: {
      info: {
         name: string;
         slug: string;
      };
   };
}
// --- finish for Props

const BlogKienThucContent: React.FC<IProps> = ({ categories, datasSSR }) => {
   const sliderRef = useRef<Slider>(null);
   const router = useRouter();
   const [slug, setSlug] = useState<string>((categories as any)?.docs?.[0]?.info?.slug ?? "");
   const [blogGalleries, setBlogGalleries] = useState<Doc[]>(datasSSR?.paginate?.docs || []);
   const [isLoading, setIsLoading] = useState<boolean>(false);

   const isSSR = typeof window === "undefined";

   /**
    * Fetch Blog Categories
    */
   const fetchCategories = async (newSlug: string) => {
      if (!slug) return;

      setIsLoading(true);
      try {
         const response = await Article.paginate({
            limit: 4,
            categorySlugs: newSlug,
            cookiesToken: "",
         });

         setBlogGalleries((response as any)?.paginate?.docs || []);
      } catch (error) {
         console.error("Failed to fetch blog categories:", error);
      } finally {
         setIsLoading(false);
      }
   };

   /**
    * Slider Settings
    */
   const carouselSettings: Settings = useMemo(
      () => ({
         dots: false,
         arrows: false,
         speed: 500,
         slidesToShow: 4,
         slidesToScroll: 4,
         infinite: false,
         responsive: [
            {
               breakpoint: 768,
               settings: {
                  slidesToShow: 3.5,
                  slidesToScroll: 3,
               },
            },
            {
               breakpoint: 576,
               settings: {
                  slidesToShow: 2.5,
                  slidesToScroll: 2,
               },
            },
         ],
      }),
      []
   );

   const handleFilterClick = (slug: string) => {
      setSlug(slug);
      fetchCategories(slug);
   };

   const renderSliderItems = () => {
      return blogGalleries.map((item: Doc) => (
         <div key={item._id} className={styles["slider-item"]}>
            <Link
               style={router.query.isApp === "true" ? { pointerEvents: "none" } : {}}
               title={item.info.title}
               href={`/blog/${slug}/${item.info.slug}`}
               className="text-decoration-none"
            >
               <div className={styles["img"]}>
                  <ImageView
                     objectFit="cover"
                     src={item.__media?.$url3And4ColResizeMax || item.__media?.$url || ""}
                     alt={item.info.title}
                     className={styles["type-space-item_image"]}
                  />
               </div>
               <div className={styles["article-item-info"]}>
                  <div className={styles["truncate-line-2"]}>{truncateText(item.info.title, 80)}</div>
               </div>
            </Link>
         </div>
      ));
   };

   /**
    * Dynamic Styles
    */
   const baseStyle = {
      display: blogGalleries?.length === 0 || isLoading ? "flex" : "block",
      justifyContent: blogGalleries?.length === 0 || isLoading ? "center" : "unset",
      alignItems: blogGalleries?.length === 0 || isLoading ? "center" : "unset",
   };

   const style = {
      ...baseStyle,
      height: isMobile ? "158px" : "256px",
   };

   const renderContent = () => {
      if (!isSSR && isLoading) {
         return <Loadingv2 />;
      }

      if (!isSSR && blogGalleries.length === 0) {
         return <SkeletonLoader />;
      }

      return (
         <Slider {...carouselSettings} ref={sliderRef}>
            {renderSliderItems()}
         </Slider>
      );
   };

   return (
      <section className={styles["construction-design"]}>
         <div className={styles["filter-cate"]}>
            {(categories as any)?.docs?.map((item) => (
               <div
                  key={item._id}
                  className={classnames(styles["filter-item"], {
                     [styles["active"]]: slug === item?.info?.slug,
                  })}
                  onClick={() => handleFilterClick(item?.info?.slug)}
               >
                  {item?.info?.name}
               </div>
            ))}
            <div
               className={classnames(styles["filter-item"], {
                  [styles["active"]]: slug === "blog/thiet-ke-noi-that",
               })}
               onClick={() => {
                  router.push("/blog/thiet-ke-noi-that");
               }}
            >
               Thiết kế nội thất
            </div>
            <div
               className={classnames(styles["filter-item"], {
                  [styles["active"]]: slug === "thi-cong-noi-that",
               })}
               onClick={() => router.push("/blog/thi-cong-noi-that")}
            >
               Thi công nội thất
            </div>
            <div
               className={classnames(styles["filter-item"], {
                  [styles["active"]]: slug === "thu-vien-e-book",
               })}
               onClick={() => router.push("/blog/thu-vien-e-book")}
            >
               Thư viện E-book
            </div>
         </div>
         <div style={style}>{renderContent()}</div>
      </section>
   );
};

export default BlogKienThucContent;

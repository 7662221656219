import { yupResolver } from "@hookform/resolvers/yup";
import { useState } from "react";
import { useForm } from "react-hook-form";
import classNames from "classnames";
import { isMobile } from "react-device-detect";
import store from "state";
import { toggleModalSuccess } from "state/global/actions";
import { ModelError, TModelError } from "@Models/model";
import yup from "../../../../core/yupPatern";
import styles from "./Form.module.scss";
import { apiDomain } from "../../../../models/__variables";

// Enums
enum EElement {
   input = "input",
   select = "select",
}

enum EType {
   number = "number",
   text = "text",
   email = "email",
   file = "file",
   tel = "tel",
}

export enum EName {
   name = "hotenkhachhang",
   phone = "dienthoaikhachhang",
   emailkhachhang = "emailkhachhang",
}

enum EInputMode {
   text = "text",
   decimal = "decimal",
   numeric = "numeric",
   tel = "tel",
   search = "search",
   email = "email",
   url = "url",
}

// Interfaces
export interface IFormInputs {
   [EName.name]: string;
   [EName.phone]: string;
   [EName.emailkhachhang]: string;
}

// Form configuration
const formFields = [
   {
      element: EElement.input,
      type: EType.text,
      name: EName.name,
      placeholder: "Điền tên đầy đủ của bạn",
      label: "Họ và tên",
      required: true,
   },
   {
      element: EElement.input,
      type: EType.number,
      inputmode: EInputMode.tel,
      name: EName.phone,
      placeholder: "Nhập số điện thoại",
      label: "Số điện thoại",
      required: true,
   },
   {
      element: EElement.input,
      type: EType.text,
      name: EName.emailkhachhang,
      placeholder: "Nhập địa chỉ email",
      label: "Email",
   },
];

// Validation schema
const schema = yup.object().shape({
   [EName.name]: yup
      .string()
      .trim()
      .required("Thông tin bắt buộc không được để trống")
      .min(2, "Tối thiểu 2 ký tự")
      .max(24, "Tối đa 24 ký tự"),
   [EName.phone]: yup
      .string()
      .trim()
      .required("Thông tin bắt buộc không được để trống")
      .min(10, "Số điện thoại phải có 10 chữ số")
      .max(10, "Số điện thoại phải có 10 chữ số"),
});

// Render Form Input
const FormField = ({ field, register, error }) => {
   const { element, type, name, placeholder, label, required, inputmode } = field;

   return (
      <div
         className={classNames(styles["form-item"], {
            [styles["error"]]: error,
         })}
      >
         <label className={styles["label"]}>
            {required && <span className="text-danger me-1">*</span>}
            {label}
         </label>
         {element === EElement.input && (
            <input
               type={type}
               inputMode={inputmode}
               className={classNames(styles["form-control"], "form-control")}
               placeholder={placeholder}
               {...register(name)}
            />
         )}
         {error && <p className={styles["text-error"]}>{error.message}</p>}
      </div>
   );
};

type Props = {
   className?: string;
   vertical?: boolean;
};

// Main Form Component
const Form: React.FC<Props> = ({ className, vertical }) => {
   const [isSubmitting, setIsSubmitting] = useState(false);
   const [errorsMes, setErrorsMes] = useState<ModelError["errors"]>();

   const {
      register,
      handleSubmit,
      formState: { errors },
      reset,
   } = useForm<IFormInputs>({
      resolver: yupResolver(schema),
   });

   const onSubmitForm = async (formData: IFormInputs) => {
      try {
         setIsSubmitting(true);
         const response = await fetch(`${apiDomain}/www/consulting/push-monday`, {
            method: "POST",
            headers: {
               "Content-Type": "application/json",
               "X-Requested-With": "XMLHttpRequest",
            },
            body: JSON.stringify(formData),
         });

         if (!response.ok) {
            throw new ModelError((await response.json()) as TModelError);
         }

         store.dispatch(
            toggleModalSuccess({
               visible: true,
               title: "Đăng kí tư vấn thành công",
               description:
                  "Đơn đăng kí tư vấn của bạn đã gửi thành công. Space T sẽ liên hệ tới bạn trong thời gian sớm nhất.",
            })
         );

         setErrorsMes(undefined);
         reset();
      } catch (err) {
         setErrorsMes(new ModelError(err).errors);
      } finally {
         setIsSubmitting(false);
      }
   };

   return (
      <div className={classNames(className, vertical ? styles["form-wrap-vertical"] : styles["form-wrap"])}>
         <h2 className={styles["title"]}>
            {vertical ? "Hãy để lại thông tin để Space T tư vấn bạn nhé!" : "Thiết kế nội thất, để Space T giúp bạn"}
         </h2>

         <form
            className={classNames(vertical ? styles["form-vertical"] : styles["form"])}
            onSubmit={handleSubmit(onSubmitForm)}
         >
            {formFields.map((field, i) => (
               <FormField
                  key={i}
                  field={field}
                  register={register}
                  error={errors[field.name] || errorsMes?.[field.name]}
               />
            ))}

            {/* Separator */}
            {vertical && (
               <div
                  style={{
                     border: "none",
                     height: "1px",
                     background: isMobile ? "#fff" : "#F0F0F0",
                     margin: "70px 0px 10px 0px",
                     marginLeft: "-24px",
                     marginRight: "-24px",
                  }}
               ></div>
            )}

            {/* Submit Button */}
            <button
               type="submit"
               className={!vertical ? classNames(styles["btn-submit"]) : classNames(styles["btn-submit-vertical"])}
               disabled={isSubmitting}
            >
               Đăng kí tư vấn
               {!vertical && (
                  <span className={styles["tag"]}>
                     <span>MIỄN PHÍ</span>
                  </span>
               )}
            </button>
         </form>
      </div>
   );
};

export default Form;

import { isMobile } from "react-device-detect";
import Image from "next/image";
import { useRouter } from "next/router";

const NhanThuongFriend = () => {
   const router = useRouter();

   return (
      <div
         style={{
            padding: isMobile ? "10px" : "0px 10px 0px 10px",
            backgroundColor: "#fff",
         }}
      >
         <div>
            <div
               style={{
                  position: "relative",
                  width: "100%",
                  height: "176px",
                  borderRadius: "12px",
               }}
            >
               {/* Background image */}
               {!isMobile ? (
                  <Image
                     src="/assets/img/construction-page/TienThuong.png"
                     alt="Example"
                     fill
                     style={{
                        objectFit: "cover",
                        borderRadius: "12px",
                     }}
                  />
               ) : (
                  <Image
                     src="/assets/img/construction-page/TienThuongMobile.png"
                     alt="Example"
                     fill
                     style={{
                        objectFit: "cover",
                        borderRadius: "12px",
                     }}
                     onClick={() => {
                        router.push("/referral-program");
                     }}
                  />
               )}

               {/* Overlayed text box, aligned to the right */}
               <div
                  style={{
                     position: "absolute",
                     top: "0px",
                     right: "8px",
                     maxWidth: "472px",
                     padding: "16px",
                     color: "#fff",
                  }}
               >
                  <h2
                     style={{
                        fontWeight: 600,
                        fontSize: isMobile ? "16px" : "20px",
                        color: "#ff6347",
                        lineHeight: "28px",
                        textAlign: "left",
                     }}
                  >
                     Giới thiệu bạn bè, nhận ngay tiền thưởng
                  </h2>
                  <p
                     style={{
                        fontSize: isMobile ? "14px" : "16px",
                        fontWeight: "400",
                        lineHeight: "24px",
                        color: "#252A2B",
                        marginTop: "8px",
                     }}
                  >
                     Nhận ngay trung bình 10% hoa hồng cho mỗi đơn hàng thành công khi giới thiệu khách hàng.
                  </p>
                  <button
                     style={{
                        backgroundColor: "#FF5722",
                        color: "#FFF",
                        border: "none",
                        borderRadius: "8px",
                        padding: isMobile ? "4px 16px" : "8px 16px",
                        cursor: "pointer",
                        width: "100%",
                        fontSize: "20px",
                        fontWeight: "500",
                     }}
                     onClick={() => {
                        router.push("/referral-program");
                     }}
                  >
                     Tham gia ngay
                  </button>
               </div>
            </div>
         </div>
      </div>
   );
};

export default NhanThuongFriend;

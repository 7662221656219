import Link from "next/link";
import BlogKienThucContent from "@Modules/homev2/components/blog-kien-thuc/BlogKienThucContent";
import styles from "./BlogKienThucWrap.module.scss";

interface IProps {
   categories?: any;
   datasSSR?: any;
}

const BlogKienThucWrap: React.FC<IProps> = ({ categories, datasSSR }) => {
   return (
      <div className={styles["block"]}>
         <div className={styles["block-sub"]}>
            <h2 className={styles["title"]}>Kiến thức </h2>
            <Link title="" href="/blog" className={styles["view-all"]}>
               <span className={styles["viewall-text"]}>Xem tất cả </span>
               <span className={styles["next-icon"]}>
                  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                     <path d="M9 6L15 12L9 18" stroke="#FF6347" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                  </svg>
               </span>
            </Link>
         </div>
         <BlogKienThucContent categories={categories} datasSSR={datasSSR} />
      </div>
   );
};

export default BlogKienThucWrap;

import Image from "next/image";
import React from "react";
import { isMobile } from "react-device-detect";
import ArchiSketch from "../../models/archisketch";
import { ModelError } from "../../models/model";
import { tokenKey } from "../../models/__variables";
import styles from "./contactUs.module.scss";

interface IState {}

interface IProps {
   thumbnail?: string;
   description?: string;
   btnTitle?: string;
   link?: string;
   handle?: () => void;
   className?: string;
   handleOpenPopupDangkyTuVan?: () => void;
}

export default class ContactUs extends React.Component<IProps, IState> {
   constructor(props: any) {
      super(props);
      this.state = {};
   }

   archiSketchLogin = async () => {
      const token = localStorage.getItem(tokenKey);
      if (!token) {
         window.open(`/?login=true&opener=https://spacet.archisketch.com`, "blank");
         return;
      }
      const res = await ArchiSketch.archiSketchLogin();
      if (res instanceof ModelError || !res) {
         throw Error("error");
      }
      window.open(res.redirectUrl, "blank");
   };

   render() {
      const { handleOpenPopupDangkyTuVan } = this.props;

      if (isMobile) {
         return (
            <div
               style={{
                  position: "relative",
                  width: "100%",
                  height: "176px",
               }}
               onClick={handleOpenPopupDangkyTuVan}
            >
               <Image src="/assets/img/construction-page/DangKyTuVanMobile.png" alt="Example" fill style={{ objectFit: "cover" }} />
            </div>
         );
      }

      return (
         <div
            style={{
               position: "relative",
               width: "100%",
               height: "176px",
               borderRadius: "12px",
            }}
         >
            <Image src="/assets/img/construction-page/DangKyTuVan2.png" alt="Example" fill style={{ objectFit: "cover", borderRadius: "12px" }} />

            <div
               style={{
                  position: "absolute",
                  top: "75px",
                  left: "50%",
                  transform: "translate(-50%, -50%)",
               }}
            >
               <h2
                  style={{
                     fontSize: "24px",
                     fontWeight: 600,
                     lineHeight: "32px",
                     textAlign: "center",
                  }}
               >
                  Space T giúp nội thất của bạn trở nên hoàn hảo hơn
               </h2>
               <div
                  style={{
                     display: "flex",
                     justifyContent: "center",
                     alignItems: "center",
                  }}
               >
                  <button type="submit" className={styles["btn-submit"]} onClick={handleOpenPopupDangkyTuVan}>
                     Đăng kí tư vấn
                     <span className={styles["tag"]}>
                        <span>MIỄN PHÍ</span>
                     </span>
                  </button>
               </div>
            </div>
         </div>
      );
   }
}

/* eslint-disable max-len */
import React, { useEffect, useState } from "react";
import styles from "./FAQ.module.scss";

const DATAS = [
   {
      title: "Tôi cần làm gì để bắt đầu quá trình làm nội thất với Space T?",
      panel: "Bạn có thể điền thông tin nhận tư vấn tại đây: <a href='https://spacet.vn/thiet-ke-thi-cong/cau-hoi-khao-sat' style='color:#05A'>https://spacet.vn/thiet-ke-thi-cong/cau-hoi-khao-sat</a><br />Hoặc bạn có thể liên hệ trực tiếp với Space T qua SĐT/Zalo <a href='tel:0848847938' style='color:#05A'>084 884 7938</a>.<br />Space T tư vấn làm nội thất hoàn toàn MIỄN PHÍ",
      index: 1,
   },
   {
      title: "Space T có những dịch vụ nội thất gì?",
      panel: "Space T cung cấp dịch vụ nội thất toàn diện: Tư vấn; Thiết kế nội thất; Thi công xây dựng; Cung cấp đồ nội thất cố định và đồ nội thất rời.",
      index: 2,
   },
   {
      title: "Quy trình làm nội thất tại Space T là như thế nào?",
      panel: "Bước 1: Tìm ý tưởng<br>Tham khảo nhiều mẫu thiết kế và công trình nội thất đẹp tại Space T để tìm ý tưởng cho căn nhà của bạn. Sau khi liên hệ và để lại thông tin nhu cầu, chuyên gia thiết kế nội thất cũng sẽ hỗ trợ tư vấn, làm moodboard giúp bạn chọn được kiểu thiết kế ưng ý và phù hợp nhất.<br><br>Bước 2: Triển khai thiết kế & thi công<br>Chuyên gia tiến hành đo đạc hiện trạng, hoàn thiện bản vẽ mặt bằng 2D các khu chức năng và bản vẽ nội thất 3D thể hiện đúng nhu cầu nội thất của bạn. Sau khi thống nhất bản vẽ và ký kết hợp đồng, đội ngũ thi công sẽ tiến hành quá trình xây dựng, cung cấp và lắp đặt nội thất. Công trình sẽ trải qua quá trình kiểm tra chất lượng nghiêm ngặt.<br><br>Bước 3: Nhận bàn giao<br>Đội ngũ giám sát sẽ nghiệm thu công trình kỹ lưỡng từng chi tiết trước khi bàn giao nên bạn hoàn toàn có thể an tâm. Space T cam kết bảo hành 1+ năm sau khi bàn giao công trình.",
      index: 3,
   },
   {
      title: "Mất bao lâu thì công trình của tôi sẽ hoàn thành khi làm việc với Space T?",
      panel: "Thời gian hoàn thiện công trình nội thất với Space T bao gồm: trao đổi, khảo sát, thiết kế, báo giá, thi công, nghiệm thu sẽ tùy vào quy mô công trình. Hoàn thiện nội thất căn hộ trung bình trong vòng 30 ngày.",
      index: 4,
   },
   {
      title: "Chế độ bảo hành khi thi công nội thất như thế nào?",
      panel: "Space T sẽ bảo hành, sửa chữa sai sót trong trường hợp phát sinh lỗi thi công nội thất theo hợp đồng mà đôi bên đã thỏa thuận.Thông thường, thời gian bảo hành thi công nội thất là 1+ năm.",
      index: 5,
   },
   {
      title: "Tôi có thể tùy chỉnh thiết kế và tùy chỉnh nội thất không?",
      panel: "Bạn hoàn toàn có thể tùy chỉnh thiết kế và đặt làm tùy chỉnh nội thất theo nhu cầu hoặc sở thích. Hãy để lại thông tin liên hệ tư vấn tại <a href='https://spacet.vn/thiet-ke-thi-cong/cau-hoi-khao-sat' style='color:#05A'>https://spacet.vn/thiet-ke-thi-cong/cau-hoi-khao-sat</a>, hoặc liên hệ trực tiếp với Space T qua SĐT/Zalo <a href='tel:0848847938' style='color:#05A'>084 884 7938</a>.",
      index: 6,
   },
];

const FAQ = () => {
   const [activeIndex, setActiveIndex] = useState<number | null>(null);
   const [isSSR, setIsSSR] = useState(true);

   useEffect(() => {
      setIsSSR(false); // Đánh dấu đã hoàn tất SSR và chuyển sang CSR
   }, []);

   const handleAccordionClick = (index: number) => {
      setActiveIndex((prevIndex) => (prevIndex === index ? null : index));
   };

   return (
      <div className={styles["faq-container"]}>
         <h2 className={styles["title"]}>Câu hỏi thường gặp</h2>
         <div className="faq_item mt-3 mt-md-3">
            <div className="row faq-item justify-content-center align-items-center">
               <div className="col-12">
                  {DATAS.map((item, index) => (
                     <div className="accordion" id="accordionExample" key={index}>
                        <div className="accordion-item">
                           <h3 className="accordion-header" id={`heading${index}`} onClick={() => handleAccordionClick(index)}>
                              <button className={`accordion-button ${isSSR || activeIndex === index ? "" : "collapsed"} ${styles["faq-item"]}`} type="button">
                                 <div className={styles["title1"]}>
                                    {item.index}. {item.title}
                                 </div>
                              </button>
                           </h3>
                           <div id={`collapse${index}`} className={`accordion-collapse collapse ${isSSR || activeIndex === index ? "show" : ""}`}>
                              <div
                                 className={styles["accordion-body"]}
                                 dangerouslySetInnerHTML={{
                                    __html: item.panel,
                                 }}
                              />
                           </div>
                        </div>
                     </div>
                  ))}
               </div>
            </div>
         </div>
      </div>
   );
};

export default FAQ;
